.welcome-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.welcome-popup h2 {
    margin-top: 0;
}

.welcome-popup button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.welcome-popup button:hover {
    background-color: #0056b3;
}


.welcome-popup img{
    width: 50%;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .welcome-popup {
        padding: 15px; /* Decrease padding for smaller screens */
        width: 95%; /* Adjust maximum width for smaller screens */
    }

    .welcome-popup h2 {
        font-size: 20px; /* Decrease font size for smaller screens */
    }

    .welcome-popup p {
        font-size: 14px; /* Decrease font size for smaller screens */
    }
}
