.music_link_box{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-areas: 
    'link_view_1 link_view_1 link_view_2'
    ;
}

.link_view_1{
    padding: 8.5em 0em;
    margin-top: -5em;
    grid-area: link_view_1;
    background-color: rgba(239, 236, 236, 0.676);  
    /* color: white; */
}
.link_view_2{
    grid-area: link_view_2;
   
}

.view_1_tab{
    display: flex;
    width: 55%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 1em 0em;
}
.all_eye{
    display: flex;
   
}
.fa-facebook{
    color: blue;
}
.fa-twitter{
    color: #29C5F6;
}
.fa-pinterest{
    color: #CA212A;
}
.fa-whatsapp{
    color: #7BBF6A;
}
.link_view_1.socail_icon i{
    margin: 0em 1em;
}
.link_view_1.socail_icon i:hover{
    cursor: pointer;
    transform: scale(1.5);
}

.link_view_1 img{
    margin: 1.5em 0em;
    width: 80%;
}
.other_mp3_info{
    padding: 3em 0em;
}
.other_mp3_info h2{
    line-height: 1.5em;
    width: 70%;
    margin: 0 auto;
}
.download_link{
    margin: 2em 0em;
}

.download_link a{
    width: 40%;
    margin: 0 auto;
}
.about_main h3{
    width: 100%;
    margin: 0 auto;
    line-height: 1.3em;
    padding: 0em 2em;
}
.fa-eye{
    margin: 0em 0.4em;
    color: red;
}
.fa-calendar-check{
    color: red;
}


audio::-webkit-media-controls-play-button,
     audio::-webkit-media-controls-panel {
     background-color: white;
     border-radius: 10%;
     margin: 0 auto;
 }

.link_view_2 img{
    padding: 0em 1.5em;
}
.both_view span{
    color: red;
    padding: 1em 0em;
}
.link_view_1.socail_icon{
   display: flex;
   justify-content: center;
}

.link_view_2{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    padding: 2em 0em;
    background-color: black;
    gap: 3em 0em;
}

@media screen and (max-width: 768px){
    .music_link_box{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-template-areas: 
        'link_view_1'
        'link_view_2'
        ;
        padding: 4em 0em;
    }
    
.link_view_1 img{
    margin: 1.5em 0em;
    width: 95%;
}
    .other_mp3_info h2{
        line-height: 0.9em;
    }
    .link_view_1.socail_icon i{
        margin: 0em 0.45em;
    }
    
.view_1_tab{
   display: grid;
   grid-template-columns: repeat(1,1fr);
   width: 80%;
   margin: 0 auto;
    /* width: 98%;
    margin: 0 auto;
    padding: 1em 0.5em; */
}
.link_view_2{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    padding: 3em 0em;
    background-color: black;
    gap: 4em 0em;
}
.about_main h3{
    width: 100%;
    margin: 0 auto;
    line-height: 1.3em;
}
.other_mp3_info h2{
    line-height: 1.5em;
    width: 92%;
    margin: 0 auto;
}
}