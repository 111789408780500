.mainPictures .picture {
    overflow: hidden;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px, auto));
    justify-content: center;
    grid-gap: 20px;
    
}
.picture img{
    width:  100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5%;
}
/* .picture img{
    width: 100%;
    overflow: hidden;
    border-radius: 5%;
} */
.outReach1{
    width: 100%;
    text-align: center;
    color: blue;
    padding: 2rem;
}

.pictureNow{
    padding-bottom: 8em;
}

@media screen and (max-width: 500px){
    .picture img{
        width: 100%;
    }
    .outReach1{
        font-size: 1.5rem;
    }
}