.aimNow{
    width: 100%;
    text-align: center;
}
.allAims h1{
    margin: 30px 0;
    color: red;
}
.ourFaith{
    width: 100%;
    text-align: center;
}
.stateSpace{
    margin: 0 5px;
}