.mainVideos{
    width: 100%;
    min-height: 100vh;
    background-color: black;
    color: white;
    padding: 3em 0em;
}
.video_page_1 h2{
    padding: 1em 0em;
}
.video_Now{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-row-gap: 2em;
}

.iframe-container{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; 
    height: 0;
  }

.iframe-container iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video_page_1{
    margin: 2em 0em;
}

  @media(max-width: 700px){
    .video_Now{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-column-gap: 4em;
    }
    .video_page_1{
        margin: 5em 0em;
    }
  }