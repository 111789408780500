.bothAll{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.bothSupportA{
    width: 100%;
    text-align: center;
}
.bothSupportB{
    margin: 2rem 0;
    width: 100%;
    text-align: center;
    padding: 0 3rem;
}
.myFormControl{
    width: 100%;
    min-height: 400px;
    border-radius: 10%;
    
    background: rgb(106,127,235);
    background: linear-gradient(90deg, rgba(106,127,235,1) 17%, rgba(215,128,128,1) 46%, rgba(142,156,230,1) 79%);
}
.myForms{
    padding: 3rem 0 0 9rem;
    width: 300px;
}
.myFormControl .myForms input{
    border: none;
    border-bottom: 2px solid blue;
    width: 100%;
    height: 30px;
}
.myFormControl .myForms textarea{
    border: none;
    border-bottom: 2px solid blue;
    width: 300px;
    height: 100px;
}
.myFormControl .myForms input:focus{
    outline: none;
}
.contactBtn{
    margin: 10px 0;
}
.mainContact{
    overflow: hidden;
}
.blink_me {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
 


@media screen and ( max-width: 500px){
    .bothAll{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
    
    .myFormControl{
        width: 100%;
        min-height: 40vh;
        border-radius: 5%;
        background-color: lightblue;
        margin-left: -10px;
    }
    .myFormControl .myForms input{
        border: none;
        border-bottom: 2px solid blue;
        width: 100%;
        height: 30px;
    }
    .myFormControl .myForms textarea{
        border: none;
        border-bottom: 2px solid blue;
        width: 200px;
        height: 100px;
    }
    .myForms{
        padding: 1rem 0 0 1rem;
        width: 100%;
    }
   
}