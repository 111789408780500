.boxCard{
    text-align: center;
    color:  white;
    display: none;
}
.mainHome{
    margin: 4.5rem 0;
}

.boxCard-1{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/How-Should-We-Welcome-the-Jesus-Christ-When-He-Knocks-on-the-Door.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    
}
.boxCard-2{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/IMG-20200120-WA0007.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    
}
.boxCard-22 img{
    width: 100%;
}
.boxCard-22{
    width: 100%;
    height: 100vh;
   
    
    
}
.boxCard-3{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/IMG_20200926_124853.jpg');
    background-position: center;
    background-size: cover;
    
    
}
.boxCard-4{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/IMG_20200926_083105.jpg');
    background-position: center;
    background-size: cover;
    
    
}
.boxCard-5{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/IMG_20200926_124849.jpg');
    background-position: center;
    background-size: cover;
    
    
}
.boxCard-6{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/IMG-20200120-WA0006.jpg');
    background-position: center;
    background-size: cover;
    
    
}
.boxCard-0{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/IMG-20200120-WA0006.jpg');
    background-position: center;
    background-size: cover;
    
    
}
.boxCard-00{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/Restored/IMG_20210404_125719443.jpg');
    background-position: center;
    background-size: cover;
    
    
}
.boxCard-7{
    width: 100%;
    height: 100vh;
    background-image: url('../../pic/IMG_20200926_092159.jpg');
    background-position: center;
    background-size: cover;
    
    
}
.welcomeTitle{
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;
    color: red;
    font-family: sans-serif;
    font-weight: 300;
}
.mainCard {
    padding: 5rem 0;
}
.boxCardH1{
    padding: 0.8rem;
}
.boxCardH2{
    padding: 1rem;
}
.welcomevIDEO video{
    width: 100%;
}
.letTalk{
    padding: 4rem 0;
    width: 100%;
    height: auto;
    text-align: center;
    color: red;
}
.aboutJesus{
    width: 100%;
    height: auto;
    text-align: center;
}
.aboutMaster img{
    width: 100%;
}
.aboutMaster h4{
    color: blue;
    font-weight: bolder;
    padding: 1rem 0;
}
.wayImg img{
    width: 100%;
}

.wayImg h3,.readyInfo,.master h1{
    color: white;
    font-weight: bolder;
}
.readyInfo{
    margin: 50px 0;
}
.fastImg img{
    width: 30px;
}
.wayInfo{
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 20px;
}
.readyInfo{
    width: 100%;
    text-align: center;
}
.weBliveMain{
    margin: 50px 0;
}
.beliveMe h1{
    width: 100%;
    text-align: center;
}
.both{
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.lastMsg img{
    width: 100%;
    margin-bottom: 30px;
}
.both ul{
    list-style: none;
}
.both P{
    text-transform: uppercase;
    padding: 1rem 2rem;
}
.verticalLine{
    border-left: 5px solid white;
    height: 600px;
    position: absolute;
    left: 50%;
}
.wayImg2:hover{
    cursor: pointer;
}
.aboutMaster img{
    margin-top: 50px;
}
.mainHome h1{
    color: blue;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
}
section{
    margin: 60px 0;
}
.aboutMaster h1{
    color: blue;
    font-weight: bolder;
}
.mobileView{
    display: none;
}
.infoWay h3{
    color: blue;
}

.aboutMaster p, .master p{
    width: 60%;
    margin: 0 auto;
}


.blink_me {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .moreJesus img{
        width: 60%;
        margin: 0 auto;
  }
 
  .aboutMaster{
      padding: 5em 0em;
  }

.billboard img{
    width: 100%;
    margin: 2.5em 0em;
}

.socialStream img{
    width: 100%;
    margin: 2.5em 0em;
}

/* Style for the streaming button */
.stream-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

/* Change background color on hover */
.stream-button:hover {
    background-color: #45a049;
}

/* Style for the event titles and times */
.event {
    margin-bottom: 20px;
}

.event-title {
    font-size: 18px;
    font-weight: bold;
}

.event-time {
    font-size: 16px;
    margin-top: 5px;
}
















@media screen and ( max-width: 768px){
    .boxCard{
        height: 30vh;
    }
    .boxCard:nth-child(4){
        height: 40vh;
    }
    .boxCard:nth-child(6){
        height: 40vh;
    }
    .boxCard:nth-child(7){
        height: 40vh;
    }
   
    .boxCard-1{
        width: 100%;
        background-image: url('../../pic/How-Should-We-Welcome-the-Jesus-Christ-When-He-Knocks-on-the-Door.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        
        
    }
    .boxCard-2{
        width: 100%;
        background-image: url('../../pic/IMG-20200120-WA0007.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        
        
    }
    .boxCard-3{
        width: 100%;
        background-image: url('../../pic/IMG_20200926_124853.jpg');
        background-position: center;
        background-size: cover;
        
        
    }
    .boxCard-4{
        width: 100%;
        background-image: url('../../pic/IMG_20200926_083105.jpg');
        background-position: center;
        background-size: cover;
        
        
    }
    .boxCard-0{
        width: 100%;
        background-image: url('../../pic/IMG-20200120-WA0006.jpg');
        background-position: center;
        background-size: cover;
        
        
    }
    .boxCard-6{
        width: 100%;
        height: 40vh;
        background-image: url('../../pic/IMG-20200120-WA0006.jpg');
        background-position: center;
        background-size: cover;
        
        
    }
    .welcomevIDEO video{
        width: 100%;
        height: auto;
    }
    .verticalLine{
        border-left: 5px solid white;
        height: 800px;
        position: absolute;
        left: 50%;
    }
    .beliveMe h1{
        font-size: 1.5rem;
    }
   
      .moreJesus img{
        width: 90%;
        margin: 0 auto;
  }
  .aboutMaster p ,.master p{
    width: 90%;
    margin: 0 auto;
}
.aboutMaster{
    padding: 3em 0em;
}
   

}

