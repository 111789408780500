.newMessage{
    margin: 40px 0;
    width: 100%;
    text-align: center;
    
}
.newMessage h1{
    color: white;
}

.messageNow{
    width: 100%;
    min-height: 100vh;
    /* background-color: black; */
    color: white;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-template-areas: 
    'newMessage_box_1 newMessage_box_1 newMessage_box_1 newMessage_box_2 newMessage_box_2'
    ;
}

.newMessage_box_1{
    grid-area: newMessage_box_1;
    background-color: #FFFFFF;
    color: black;
    padding: 2em 1em;
}
.newMessage_box_2{
    grid-area: newMessage_box_2;
    background-color: green;
    padding: 2em 1em;
}

.social_img{
    width: 50%;
    border-radius: 50%;
    padding: 1.5em 0em;
}

.message_flow_home{
    padding: 6em 1em;
    background-color: black;
    color: white;
    margin: 4em 0em;
}
.desc_text{
    margin-bottom: 2em;
    width: 90%;
    margin: 0 auto;
    line-height: 1.5em;
}
.underline_line{
    width: 100;
    color: red;
    background-color: red;
}
.icon_star{
    color: rgba(255, 255, 0, 0.697);
}

.btn_readMore{
    margin: 1.5em;
}

.viewed_tag{
    color: red;
}


@media screen and ( max-width: 500px){
    .newMessage{
        margin: 40px 0;
        width: 100%;
        text-align: center;
    }
    .newMessage h1{
        /* color: red; */
        font-size: 1.5rem;
    }
    .messageNow{
    width: 100%;
    min-height: 100vh;
    /* background-color: black; */
    color: white;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-template-areas: 
    'newMessage_box_1'
    'newMessage_box_2'
    ;
    }
    .social_img{
        width: 98%;
        border-radius: 50%;
        padding: 1em 0em;
    }
    .desc_text{
        margin-bottom: 2em;
        width: 98%;
        margin: 0 auto;
    }
}