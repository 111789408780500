.messageDisplayNow {
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
        'newMessageDisplay_box_1'
        'newMessageDisplay_box_2';
}

.newMessageDisplay_box_1 {
    grid-area: newMessageDisplay_box_1;
    background-color: #fff;
    color: #333;
    padding: 2em 1em;
    text-align: left;
}

.newMessageDisplay_box_2 {
    grid-area: newMessageDisplay_box_2;
    background-color: #ff4081; /* Pink color for contrast */
    color: #fff;
    padding: 2em 1em;
}

.back_rootMatch_h5 {
    text-align: left;
    cursor: pointer;
}

.social_on_image_message {
    width: 100%;
    margin: 1.5em 0;
}

.about_main_h5 {
    line-height: 1.5em;
    margin: 2em 0;
}

.book_title_h1 {
    color: #333;
    font-weight: bold;
    width: 60%;
}

.social_icons {
    width: 40%;
    margin: 0 auto;
}

.social_icons h3 {
    margin-bottom: 1em;
}

.advt__main img {
    width: 100%;
}

.advt__main {
    margin: 6em 0;
}

@media screen and (max-width: 700px) {
    .messageDisplayNow {
        min-height: 70vh;
        grid-template-areas: 
            'newMessageDisplay_box_1'
            'newMessageDisplay_box_2';
    }

    .social_on_image_message {
        margin: 1.5em 0;
        width: 100%;
    }

    .about_main_h5 {
        width: 99%;
    }
    .social_icons {
        width: 60%;
        margin: 0 auto;
    }
    .book_title_h1 {
        color: #333;
        font-weight: bold;
        width: 95%;
    }
}
