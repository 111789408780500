.missionForNow{
    width: 100%;
    text-align: center;
}
.allMissionVideos{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
}
.VideMiss{
    width: 700px;
}
.VideMiss video{
    width: 100%;
}
.mainMission{
    margin: 40px 0;
}
.mainMission h1{
    color: red;
}
.mtd{
    margin: 30px 0;
}


@media screen and (max-width: 500px){
    .missionVideo h1{
        font-size: 1rem;
    }
    .VideMiss{
    width: 100%;
    }
    .mainMission h1{
        font-size: 1.5rem;
    }
}